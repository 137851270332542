<template>
  <!--begin::Brand-->
  <div
    class="aside-brand d-flex flex-column align-items-center flex-column-auto py-4 py-lg-8"
  >
    <!--begin::Logo-->
    <router-link to="/">
      <img :src="siteLogo()" alt="Logo" class="max-h-30px" />
    </router-link>
    <!--end::Logo-->
  </div>
  <!--end::Brand-->
</template>


<script>

export default {
  name: "KTBrand",
  mounted() {},
  methods: {
    siteLogo() {
      if(this.$root.mobile)
      {
        return "/media/logos/logo.png";
      }
      else
        return "/media/logos/logo-white.png";
    }
  },
};
</script>
